#launcher-frame {
  bottom: 5px !important;
  right: 5px !important;
}

.base-form, .first-step-container {
  margin-bottom: 4rem;
}

.signalement-filter-form {
  bottom: 65px !important;
}