@import "~@suezenv/react-theme-components/assets/css/main.css";

html,
body,
#root {
    height: 100%;
    overflow: hidden;
}
#freshworks-container {
    #freshworks-frame-wrapper {
        z-index: 214748364799 !important;
    }
}
