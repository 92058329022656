@import "./../../../scss/breakpoints";

.menu-wrapper {
    z-index: 1001;

    ul {
        background-color: white;
        height: calc(100vh - 50px);
    }

    .sz-aside__item.active.active:before {
        background-color: var(--gray-200);
        border-left: var(--secondary) solid 5px;
    }

    .add-signal-icon {
        color: var(--primary-brand);
    }

    .menu-footer {
        z-index: 1;
        position: absolute;
        bottom: 0;
        background-color: var(--primary-dark);
        text-align: center;
        width: 100%;
        color: white;
        border-top: 2px solid var(--primary-brand);
    }

    // fix bug on menu when default closed.
    @include media-breakpoint-down(sm) {
        .sz-aside {
            &:not(.sz-aside-enter-done) {
                width: 0;
                transition: 0.4s linear all;
            }
        }
    }

    .sz-aside .footer-open {
        display: none;
    }

    .sz-aside.sz-aside-enter-done .footer-open {
        display: inline;
    }

    .sz-aside__list > a {
        text-decoration: none;
    }

    .sz-aside__list{
        height: calc(100vh - 50px) !important;
    }

    
}
