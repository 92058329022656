.header-wrapper {
  height: 50px;
  padding: 5px 0;

  .menu-icon {
    width: 70px;
    text-align: center;
    border-right: 1px solid var(--secondary)
  }

  .menu-selected {
    span {
      margin-right: 15px;
      .menu-title {
        position: relative;
        top: -4px;
      }
    }

    li:last-child {
      border-width: 0 !important;
    }

    .dropdown-menu {
      z-index: 9999;
    }
  }

  h1 {
    margin-left: 10px;
    margin-top: 6px;
  }
}

.signal-view-selector {
  .RRT__container {
    .RRT__tabs {
      flex-direction: row;
      margin: 0;
    }

    .RRT__accordion .RRT__tab--collapsed {
      margin-bottom: 0;
      font-size: 17px;
    }
  }
  
}
