$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

@import "~bootstrap/scss/mixins/breakpoints";

.fs-05 {
  font-size: 0.5rem;
}

.fs-07 {
  font-size: 0.7rem;
}

.fs-09 {
  font-size: 0.9rem;
}